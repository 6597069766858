<template>
  <div>
    <div class="yusu-top10-main">
      <section class="yusu-top10-title">
        <img :src="imgTopTitle" alt="" />
      </section>
      <section class="yusu-top10-dou">
        <div class="yusu-top10-type-title">
          <img :src="imgTopTitle1" alt="" />
        </div>
        <div
          v-for="(douItem, index1) in topImg.douList"
          :key="index1"
          class="yusu-top-product"
          @click="onGoDetail(douItem.num)"
        >
          <img :src="douItem.url" alt="" />
        </div>
      </section>
      <section class="yusu-top10-skinFix">
        <div class="yusu-top10-type-title">
          <img :src="imgTopTitle2" alt="" />
        </div>
        <div
          v-for="(skinFixItem, index2) in topImg.skinFixList"
          :key="index2"
          class="yusu-top-product"
          @click="onGoDetail(skinFixItem.num)"
        >
          <img :src="skinFixItem.url" alt="" />
        </div>
      </section>
      <section class="yusu-top10-careSkin">
        <div class="yusu-top10-type-title">
          <img :src="imgTopTitle3" alt="" />
        </div>
        <div
          v-for="(careSkinItem, index3) in topImg.careSkinList"
          :key="index3"
          class="yusu-top-product"
          @click="onGoDetail(careSkinItem.num)"
        >
          <img :src="careSkinItem.url" alt="" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// 封面图
import imgTopTitle from '../../assets/images/top10/top-title.png'
import imgTopTitle1 from '../../assets/images/top10/qudou.png'
import imgTopTitle2 from '../../assets/images/top10/xiuhu.png'
import imgTopTitle3 from '../../assets/images/top10/base.png'
import imgTop01 from '../../assets/images/top10/top01/top-01.png'
import imgTop02 from '../../assets/images/top10/top02/top-02.png'
import imgTop03 from '../../assets/images/top10/top03/top-03.png'
import imgTop04 from '../../assets/images/top10/top04/top-04.png'
import imgTop05 from '../../assets/images/top10/top05/top-05.png'
import imgTop06 from '../../assets/images/top10/top06/top-06.png'
import imgTop07 from '../../assets/images/top10/top07/top-07.png'
import imgTop08 from '../../assets/images/top10/top08/top-08.png'
import imgTop09 from '../../assets/images/top10/top09/top-09.png'
import imgTop10 from '../../assets/images/top10/top10/top-10.png'

export default {
  name: 'popular-top10',
  data: () => {
    return {
      imgTopTitle: imgTopTitle,
      imgTopTitle1: imgTopTitle1,
      imgTopTitle2: imgTopTitle2,
      imgTopTitle3: imgTopTitle3,
      topImg: {
        douList: [
          {
            url: imgTop01,
            num: 1,
          },
          {
            url: imgTop02,
            num: 2,
          },
        ],
        skinFixList: [
          {
            url: imgTop03,
            num: 3,
          },
          {
            url: imgTop04,
            num: 4,
          },
          {
            url: imgTop05,
            num: 5,
          },
          {
            url: imgTop06,
            num: 6,
          },
        ],
        careSkinList: [
          {
            url: imgTop07,
            num: 7,
          },
          {
            url: imgTop08,
            num: 8,
          },
          {
            url: imgTop09,
            num: 9,
          },
          {
            url: imgTop10,
            num: 10,
          },
        ],
      },
    }
  },
  methods: {
    // 跳转详情页
    onGoDetail(query) {
      this.$router.push({
        path: 'top10/detail',
        query: {
          productId: query,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.yusu-top10-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  .yusu-top10-title {
    display: flex;
    justify-content: center;
    padding: 62px;
    position: relative;
  }
  .yusu-top10-dou,
  .yusu-top10-skinFix,
  .yusu-top10-careSkin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .yusu-top10-type-title {
    position: relative;
    padding: 30px 0;
  }
  .yusu-top-product {
    margin: 40px;
    cursor: pointer;
  }
}
</style>
